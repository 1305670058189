<template>
  <div>
    <span v-if="debugMode" class="kiosk-info">
      <div>
        <span v-if="kiosk">Name: {{ kiosk.name }} (ID: {{ kiosk._id }})</span>
        <span v-if="kiosk.asset"> | Asset: {{ kiosk.asset.siteName }} (ID: {{ kiosk.assetId }})</span>
      </div>
      <div>
        <span v-if="debug.lastRefresh">Last refresh (kiosk): {{ debug.lastRefresh }} ({{ debug.lastRefreshDuration }}s)</span>
        <span v-if="debug.lastAssetRefresh"> | Last refresh (asset): {{ debug.lastAssetRefresh }} ({{ debug.lastAssetRefreshDuration }}s)</span>
      </div>
    </span>
    <span v-if="!isOnline" class="kiosk-error text-danger">
      <span class="text-danger font-size-h1">No internet connection. If this problem persists please contact Etainabl support.</span></span
    >
    <div v-if="kiosk._id" :key="kiosk._id">
      <keep-alive>
        <component :is="currentSlide.component" :data="currentSlide.data" :hide="hideSlide" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KioskView',
  data() {
    return {
      debugMode: false,
      interval: null,
      isOnline: true,
      onlineTimeout: null,
      hideSlide: false,
      touch: {
        startX: 0,
        endX: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      debug: 'kioskLive/debug',
      slideIndex: 'kioskLive/slideIndex'
    }),
    currentSlide() {
      const slideName = this.kiosk.slides[this.slideIndex];
      return {
        component: () => import(`./slides/Kiosk${slideName}`),
        data: {}
      };
    }
  },
  watch: {
    'kiosk.slideInterval'(a, b) {
      if (a !== b) this.resetTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);

    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    window.removeEventListener('keyup', null);
    window.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchend', this.onTouchEnd);
  },
  async mounted() {
    // Hide scroll
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    // Listen for online/offline events
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);

    // Initial refresh
    this.setLoading(true);
    await this.refresh();

    if (this.$route.query.debug === 'true') {
      this.debugMode = true;
    }

    this.resetTimer();

    window.addEventListener('keyup', e => {
      if (e.key === 'ArrowRight' || e.key === 'Enter') {
        this.transition();
        this.resetTimer();
      }
      if (e.key === 'ArrowLeft') {
        this.transition(true);
        this.resetTimer();
      }
    });

    if (this.$route.query.slide) {
      this.setSlideIndex(parseInt(this.$route.query.slide, 10));
    }

    this.interval = setInterval(this.refresh, 15 * 60 * 1000);
  },
  methods: {
    ...mapActions({
      getKioskInfo: 'kioskLive/getInfo',
      getKioskConsumption: 'kioskLive/getConsumption'
    }),
    ...mapMutations({
      setDebug: 'kioskLive/SET_DEBUG',
      setLoading: 'kioskLive/SET_LOADING',
      setSlideIndex: 'kioskLive/SET_SLIDE_INDEX'
    }),
    async refresh() {
      const refreshTimer = new Date().getTime();
      this.setLoading(true);

      // Kiosk-level data
      await this.getKioskInfo();
      await this.getKioskConsumption();

      this.setDebug({
        lastRefresh: moment().format('Do MMM YYYY HH:mm:ss'),
        lastRefreshDuration: ((new Date().getTime() - refreshTimer) / 1000).toFixed(2)
      });

      this.setLoading(false);
    },
    updateOnlineStatus(e) {
      const { type } = e;

      console.log(type);

      const isOnline = type === 'online';

      if (!isOnline) {
        this.onlineTimeout = setTimeout(() => {
          this.isOnline = isOnline;
        }, 1000);
      } else {
        clearTimeout(this.onlineTimeout);
        this.isOnline = isOnline;
      }
    },
    transition(back = false) {
      this.hideSlide = true;

      setTimeout(() => {
        this.hideSlide = false;

        if (this.$route.query.slide || this.$route.query.slide === 0) {
          this.setSlideIndex(this.$route.query.slide);
        } else if (!back) {
          if (this.slideIndex >= this.kiosk.slides.length - 1) {
            this.setSlideIndex(0);
          } else {
            this.setSlideIndex(this.slideIndex + 1);
          }
        } else {
          if (this.slideIndex === 0) {
            this.setSlideIndex(this.kiosk.slides.length - 1);
          } else {
            this.setSlideIndex(this.slideIndex - 1);
          }
        }
      }, 750);
    },
    resetTimer() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.transition();
      }, this.kiosk.slideInterval || 30000);
    },
    onTouchStart(e) {
      this.touch.startX = e.changedTouches[0].screenX;
    },
    onTouchEnd(e) {
      this.touch.endX = e.changedTouches[0].screenX;
      this.resetTimer();

      if (this.touch.endX < this.touch.startX) {
        this.transition();
        this.resetTimer();
      }

      if (this.touch.endX > this.touch.startX) {
        this.transition(true);
        this.resetTimer();
      }
    }
  }
};
</script>
